import React, { createContext, useContext, useReducer, Dispatch, useEffect } from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { Status } from '../components/ResourceStatus'
import { TokenType } from '../components/instances/InstanceCard'

interface CurrentInstanceState {
  id?: string
  status?: Status
  version?: string
  codename?: string
  createdAt?: string
  updatedAt?: string
  token?: TokenType
}
interface CurrentInstanceProviderProps {
  children: any
}

/**
 * CurrentInstanceContextProps clarification:
 * - state value is null if not in Traefik context
 * - state value is { id: null } if in Traefik context but current instance is not registered yet
 */
type CurrentInstanceContextProps = {
  state: CurrentInstanceState
  dispatch: Dispatch<any>
}

type UpdateInstanceAction = {
  type: 'UPDATE_INSTANCE'
  value: CurrentInstanceState
}
type SetTokenAction = {
  type: 'SET_TOKEN'
  value: TokenType
}

type DeleteTokenAction = {
  type: 'DELETE_TOKEN'
}

type ContextAction = UpdateInstanceAction | SetTokenAction | DeleteTokenAction

const reducer = (state: CurrentInstanceState, action: ContextAction) => {
  switch (action.type) {
    case 'UPDATE_INSTANCE':
      return { ...state, ...action.value }
    case 'SET_TOKEN':
      return { ...state, token: action.value }
    case 'DELETE_TOKEN':
      return { ...state, token: null }
    default:
      return state
  }
}

export const CurrentInstanceContext = createContext({} as CurrentInstanceContextProps)

export const CurrentInstanceProvider = ({ children }: CurrentInstanceProviderProps) => {
  const { query } = useRouter()
  const [state, dispatch] = useReducer(reducer, null)
  const { data: currentInstanceData, error: currentInstanceError } = useSWR(
    state?.id ? `/api/services/tokens/?instanceId=${state?.id}` : null,
  )

  const getInitialState = (): CurrentInstanceState => {
    if (query.instanceInfos) {
      try {
        const instanceInfos = JSON.parse(query.instanceInfos as string)
        const instance = {
          id: instanceInfos.uuid?.length ? instanceInfos.uuid : null,
          version: instanceInfos.Version || instanceInfos.version,
          codeName: instanceInfos.codeName || instanceInfos.CodeName || instanceInfos.Codename,
          createdAt: instanceInfos.startDate || instanceInfos.StartDate || instanceInfos.Startdate,
        }

        return instance
      } catch (error) {
        console.error('error parsing instanceInfos', error)
      }
    }

    return null
  }

  useEffect(() => {
    if (query.instanceInfos) {
      dispatch({ type: 'UPDATE_INSTANCE', value: getInitialState() })
    }
  }, [query.instanceInfos])

  useEffect(() => {
    if (currentInstanceError) {
      dispatch({ type: 'UPDATE_INSTANCE', value: { id: null } })
    }
  }, [currentInstanceError])

  useEffect(() => {
    if (state?.id && currentInstanceData?.data) {
      dispatch({ type: 'SET_TOKEN', value: currentInstanceData?.data })
    }
  }, [state?.id, currentInstanceData])

  return <CurrentInstanceContext.Provider value={{ state, dispatch }}>{children}</CurrentInstanceContext.Provider>
}

export const useCurrentInstance = () => useContext(CurrentInstanceContext)
