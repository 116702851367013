import * as React from 'react'
import { ToastState } from '../components/Toast'

const hideToastFilter = (toast: ToastState) => (t: ToastState) => {
  return t.key !== toast.key
}

interface ToastProviderProps {
  children: React.ReactNode
}

interface ToastContextProps {
  toasts: ToastState[]
  addToast: (toast: ToastState) => void
  hideToast: (toast: ToastState) => void
  hideAllToasts: () => void
}

export const ToastContext = React.createContext({} as ToastContextProps)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const ToastProvider = (props: ToastProviderProps) => {
  const [toasts, setToastList] = React.useState<ToastState[]>([])
  const addToast = React.useCallback(
    (toast: ToastState) => {
      toast.key = Date.now()
      setToastList((toasts) => [...toasts, toast])
    },
    [setToastList, toasts],
  )

  const hideToast = React.useCallback(
    (toast: ToastState) => {
      setToastList((toasts) => toasts.filter(hideToastFilter(toast)))
    },
    [setToastList],
  )

  const hideAllToasts = React.useCallback(() => {
    setToastList([])
  }, [setToastList])

  const value: ToastContextProps = { toasts, addToast, hideToast, hideAllToasts }

  return <ToastContext.Provider value={value}>{props.children}</ToastContext.Provider>
}
